<template>
  <b-modal v-model="show" title="test" size="md" centered @hidden="show=false">
    <template #modal-header>
      <h5 class="modal-title">Print all users</h5>
      <button class="close" @click="show=false"></button>
    </template>
    <div class="print-modal" v-if="!loading">
      <div class="row">
        <div class="col-12">
          <div class="print-events py-3">
            <div class="print-event"
                 :class="{'selected': event.id === eventId}"
                 v-for="event in events"
                 :key="event.id"
                 @click="selectEvent(event)">
              {{event.title}}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="alert alert-warning text-center d-flex flex-column px-2">
            <template v-if="!countLoading">
              <span style="font-size: 48px">{{userCount}}</span>
              <span class="fw-500">Человек(а) еще не пришло</span>
            </template>
            <Loading v-else />
          </div>
        </div>
      </div>
      <div class="form-group mb-0">
        <label>Укажите пароль администратора:</label>
        <input type="password" class="form-control" placeholder="Enter password" v-model="adminPassword">
      </div>
    </div>
    <Loading v-else />
    <template #modal-footer>
      <button class="btn btn-secondary mr-2" @click="show=false">Cancel</button>
      <button class="btn btn-brand" v-if="adminPassword === '2003'" @click="handlePrintAll">
        <template v-if="!btnLoading">Print</template>
        <b-spinner small v-else></b-spinner>
      </button>
    </template>
  </b-modal>
</template>

<script>
// import {getAllTickets} from "@/helpers/tickets/tickets";
import {getPrintCount, printAll} from "@/helpers/checkin/checkin.js"
import {getAllEvents} from "@/helpers/event/event.js";
export default {
  name: "PrintModal",
  props: ['value'],
  emits: ['input'],
  data() {
    return {
      loading: false,
      countLoading: false,
      btnLoading: false,
      userCount: 0,
      adminPassword: '',
      eventId: null,
      events: []
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    show: {
      get() {
        return this.value ? this.value : false
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.loading = true
    if (this.user.role.role === 'admin') {
      getAllEvents({st_id: 2}).then(response => {
        this.events = response.data.data
        this.eventId = this.events[0].id
        if (this.eventId) {
          this.selectEvent()
        }
      }).catch(error => {
        this.$swal(this.$t("general.error"), error.data.message, "error")
      })
    } else if (this.user.events && this.user.events.length) {
      this.events = this.user.events
      this.eventId = this.events[0].id
      if (this.eventId) {
        this.selectEvent()
      }
    }
  },
  methods: {
    selectEvent(event) {
      event ? this.eventId = event.id : null
      this.countLoading = true
      getPrintCount(this.eventId).then(res => {
        this.userCount = res.data
      }).finally(() => {
        this.loading = false
        this.countLoading = false
      })
    },
    handlePrintAll() {
      this.btnLoading = true
      printAll(this.eventId).then(response => {
        this.show = false
        
        const blob = response.data
        const link = document.createElement('a')
        const URL = window.URL || window.webkitURL
        link.href = URL.createObjectURL(blob);
        link.download = 'unchecked_stickers'
        link.click()
      }).finally(() => {
        this.btnLoading = false
      })
    }
  },
  watch: {
    value: function (show) {
      if(show && this.eventId) {
        this.selectEvent()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.fw-500
  font-weight: 500
.print-event
  display: inline-block
  padding: 8px 16px
  border: 1px solid #eee
  border-radius: 5px
  margin-right: 5px
  margin-bottom: 5px
  font-weight: 500
  cursor: pointer
  &:hover
    border-color: #5d78ff
  &.selected
    background: #5d78ff
    color: #fff
</style>